<template>
  <div>
    <Back>Назад</Back>
    <h1>Ошибка 404</h1>
    <div>Страница не найдена или удалена</div>
  </div>
</template>

<script>
import Back from '@/components/Back'

export default {
  name: 'NotFound404',

  components: { Back },
}
</script>
